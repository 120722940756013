// /* eslint-disable jsx-a11y/img-redundant-alt */
// /* eslint-disable react-hooks/exhaustive-deps */
import { getCookie } from "shared/Assets/JS/Cookies";
import axios from "axios";
import React, { useEffect, useRef } from "react";
import { deleteFileApi, uploadFileApi } from "shared/utils/interaction";

const imagePaths = {
    checkList: "/images/checkList",
    productTourTemplate: "/images/productTourTemplate",
    productTour: "/images/productTour",
    eeLayout: "/images/eeLayout",
    eeEntryPoint: "/images/eeEntryPoint",
    eeJourneyPoint: "/images/eeJourneyPoint",
    eeMarketplaceTemplate: "/images/eeMarketplaceTemplate",
    npsElementType: "/images/npsElementType",
    temporaryImages: "/images/temporaryImages",
    nps: "/images/nps",
    npsQuestion: "/images/npsQuestion",
    userProfile: "/images/userProfile",
    generalSetting: "/images/generalSetting",
    companyImage: "/images/companyImage",
    accountTheme: "/images/accountTheme",
    checklistImage: "/images/checklistImage",
    organizationImage: "/images/organizationImage",
};

const BASE64_REGEX = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

const generateFileName = (name = null) => {
    let timestamp = new Date().toISOString().replace(/[-:.]/g, "");
    let random = ("" + Math.random()).substring(2, 8);
    if (name) {
        return name + "_" + timestamp + random;
    } else {
        return timestamp + random;
    }
};

const isBase64Encoded = (path) => BASE64_REGEX.test(path.split(",")[1]);

const Image = ({ src, fallbackImage = null, ...rest }) => {
    let urlWithSubdomainAdmin = `${process.env.REACT_APP_GRAPHQL_SERVER}`.split("//");

    function handleImageError(e) {
        e.currentTarget.onerror = null;
        e.target.src = fallbackImage;
    }

    const link = `${urlWithSubdomainAdmin[0]}//${urlWithSubdomainAdmin[1]}`;

    const ref = useRef();
    const token = getCookie("admin_access_token");

    useEffect(() => {
        if (src) {
            if (!isBase64Encoded(src)) {
                axios({
                    method: "get",
                    url: `${link}${src}`,
                    responseType: "blob",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "sub-domain": getCookie("admin_sub_domain"),
                    },
                }).then(({ data }) => {
                    ref.current.src = URL.createObjectURL(data);
                });
            } else {
                ref.current.src = src;
            }
        }
    }, [src, token]);

    return (
        <img
            src={fallbackImage}
            ref={ref}
            {...rest}
            onError={handleImageError}
            alt="No image found "
        />
    );
};

const ImageUploadToAWS = async (file, src, accountId) => {
    try {
        if (!file || !src || !accountId)
            return { status: false, message: "File & Path is Required" };
        let sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
        if (sizeInMB > 2) {
            return { status: false, message: "Image should be less than 2MB" };
        }
        const response = await uploadFileApi(file, src, accountId); // Update the function call
        if (!response || !response.status) {
            return {
                status: false,
                message: response?.message || "Error From API",
            };
        } else {
            return {
                status: true,
                originalName: file?.name,
                data: response.data,
            };
        }
    } catch (error) {
        return {
            status: false,
            message: error?.message,
        };
    }
};

const deleteImageFromAWS = async (src) => {
    try {
        const response = await deleteFileApi(src); // Update the function call
        if (!response || !response.status) {
            return {
                status: false,
                message: response?.message || "Error From API",
            };
        } else {
            return {
                status: true,
                data: response.data,
                message: "Success",
            };
        }
    } catch (error) {
        return {
            status: false,
            message: error?.message,
        };
    }
};

export { Image, ImageUploadToAWS, imagePaths, deleteImageFromAWS };
